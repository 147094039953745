<template>
  <div class="ajuda-app" @scroll="scroll">
    <ajuda-header />
    <div class="ajuda-page">
      <router-view />
    </div>
    <footer>
      Portal de ajuda e documentação do ERP da Suporte Leilões<br>
      <a href="https://www.suporteleiloes.com.br">www.suporteleiloes.com.br</a>
    </footer>
  </div>
</template>

<script>
import AjudaHeader from 'components/ajuda/components/Header'
export default {
  name: "AjudaPage",
  components: {AjudaHeader},
  mounted() {
    //document.querySelector('.ajuda-app').addEventListener('scroll', this.scroll);
  },
  beforeDestroy() {
    //document.querySelector('.ajuda-app').removeEventListener('scroll', this.scroll);
  },
  methods: {
    scroll: function (event) {
      const stickyDiv = document.querySelector('.suporte-header');
      if (event.target.scrollTop > 0) {
        stickyDiv.classList.add('with-shadow');
      } else {
        stickyDiv.classList.remove('with-shadow');
      }
    }
  }
}
</script>
