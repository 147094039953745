<template>
  <header class="suporte-header min">
    <div class="header-content">
      <div class="line1">
        <div><a class="help-title">Ajuda da Suporte Leilões</a></div>
        <div class="login-bar">
          <div class="suporte-profile-menu">
            <div class="cprofile">
              <div v-if="$uloc.auth && $uloc.auth.logged" class="person-avatar-name">
                <div class="person-avatar avatar inline">
                  <!--          <img v-if="$uloc.auth.session.user.photo" width="25" class="" :src="$uloc.auth.session.user.photo"/>
                            <div v-else class="img-fake">{{ firstLettersName($uloc.auth.session.user.name) }}</div>-->
                  <div class="img-fake">{{ firstLettersName($uloc.auth.session.user.name) }}</div>
                </div>
                <div class="name">Olá, <span class="uppercase">{{firstname($uloc.auth.session.user.name)}}!</span></div>
              </div>
            </div>
            <div class="config support">
              <router-link tag="a" :to="{name: 'suporte.dashboard'}">
                <i class="menu-icon fa fa-envelope"></i> <span>Console de Suporte</span>
              </router-link>
            </div>
          </div>
          <div class="bar-border">
            <router-link v-if="$uloc.auth && $uloc.auth.logged" tag="a" :to="{name: 'logout'}">Sair</router-link>
            <router-link v-else tag="a" :to="{name: 'login'}">Entrar</router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "AjudaHeader",
  provide: function () {
    return {
      header: this
    }
  },
  data () {
    return {
      menu: [
        {name: 'suporte', label: 'Suporte', icon: 'fal fa-ticket', route: 'suporte.dashboard'},
        {name: 'doc', label: 'Documentação', icon: 'fal fa-book', route: 'ajuda.home'},
        {name: 'changelog', label: 'Changelog', icon: 'fal fa-code-branch', disabled: true},
        {name: 'api', label: 'API', icon: 'fal fa-code', disabled: true},
        {name: 'contract', label: 'Contrato SL', icon: 'fal fa-file-contract', disabled: true},
        {name: 'financeiro', label: 'Financeiro', icon: 'fal fa-money-check', disabled: true},
      ],
      menuActive: 'suporte'
    }
  },
  computed: {

  },
  components: {}
}
</script>
